import type { NextPage } from 'next'
import Head from 'next/head'

import { Container, Main } from 'styles/main'
import 'mapbox-gl/dist/mapbox-gl.css'
import axios from 'axios'
import OrderStatus from 'components/OrderStatus'
import OrderStatusMobile from 'components/mobile/OrderStatusMobile'

import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import styled from 'styled-components'

const OrderStatusContainerDesktop = styled.div`
  display: flex;
  justify-content: center;
  @media only screen and (min-width: 801px) {
    width: 100%;
    height: 500px;
  }
  @media only screen and (max-width: 800px) {
    display: none;
  }
`

const OrderStatusContainerMobile = styled.div`
  @media only screen and (min-width: 801px) {
    display: none;
  }
  @media only screen and (max-width: 800px) {
    width: 100%;
    height: 500px;
  }
`
const Home: NextPage = () => {
  const queryClient = new QueryClient()

  return (
    <Container>
      <Head>
        <title>Delivery Tracker</title>
        <meta name="description" content="Delivery Tracker" />
        {/* /* eslint-disable */}
        <link
          href="https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@400;500;700&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://api.mapbox.com/mapbox-gl-js/v2.8.2/mapbox-gl.css"
          rel="stylesheet"
        />
      </Head>
      {/* <Main>Delivery Tracker - Home </Main> */}
      <QueryClientProvider client={queryClient}>
        <OrderStatusContainerDesktop>
          <OrderStatus />
        </OrderStatusContainerDesktop>
        <OrderStatusContainerMobile>
          <OrderStatusMobile />
        </OrderStatusContainerMobile>
      </QueryClientProvider>
    </Container>
  )
}

export default Home
